import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  RefreshSharp,
  ShoppingBag,
  PreviewSharp,
  PaymentSharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo, useContext } from "react";
import PurchaseModelHistory from "./PurchaseModelHistory";
import NewOrder from "../Products/NewOrder";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import PaymentsModel from "../PaymentsModel";
import { format } from "date-fns";
import PurchaseReceiptModel from "./PurchaseReceiptModel";
import PurchaseProformaModel from "./PurchaseProformaModel";
import { useTheme } from "@mui/material/styles";

//import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function PurchaseVouchersComp(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [VouchersList, setVouchersList] = useState([]);
  const [LineItemsList, setLineItemsList] = useState([]);
  const [receivedVouchersList, setReceivedVouchersList] = useState([]);
  const [receivedLineItemsList, setReceivedLineItemsList] = useState([]);
  const [proformaVouchersList, setProformaVouchersList] = useState([]);
  const [proformaLineItemsList, setProformaLineItemsList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);

  const {
    globalUser,
    globalProviders,
    globalRoleSecurity,
    setPageHeader,
    globalToken,
    setSelectedPurchaseType,
  } = useContext(ShoppingCartContext);

  const [employeeList, setEmployeeList] = useState([]);
  const [loadTable, setLoadTable] = useState(false);
  const [bankList, setBankList] = useState([]);

  const purchaseColumns = useMemo(
    () => [
      {
        header: "Order No",
        accessorKey: "OrderId",
        size: "100",
      },
      {
        header: "Supplier",
        accessorKey: "Name",
      },

      {
        header: "Requested By",
        accessorKey: "RequestedByName",
      },
      {
        header: "Approved By",
        accessorKey: "ApprovedByName",
      },

      {
        header: "Date",
        accessorKey: "VoucherDate",
        size: "80",
      },

      /*  {
        header: "Sub Total",
        accessorKey: "Amount",
        size: "150",
      },
      {
        header: "VAT Amount",
        accessorKey: "VATAmount",
        size: "150",
      },
     */
      {
        header: "Total Amount",
        accessorKey: "TotalAmount",
        size: "150",
      },
      {
        header: "Paid Amount",
        accessorKey: "PaidAmount",
        size: "150",
      },

      /* 
      {
        header: "Status",
        accessorKey: "Status",
        size: "80",
      }, */
    ],
    []
  );
  const purchaseReturnColumns = useMemo(
    () => [
      {
        header: "Order Id",
        accessorKey: "OrderId",
        size: "100",
      },
      {
        header: "Supplier",
        accessorKey: "Name",
      },
      {
        header: "Date",
        accessorKey: "VoucherDate",
        size: "80",
      },

      {
        header: "Total Amount",
        accessorKey: "TotalAmount",
        size: "150",
      },
      {
        header: "Returned By",
        accessorKey: "ReturnedByName",
      },
      {
        header: "Returned Date",
        accessorKey: "ReturnedDate",
      },
      {
        header: "Status",
        accessorKey: "Status",
        size: 80,
      },
    ],
    []
  );
  const requestColumns = useMemo(
    () => [
      {
        header: "Order No",
        accessorKey: "OrderId",
        size: "100",
      },
      {
        header: "Requested By",
        accessorKey: "RequestedByName",
      },
      {
        header: "Approved By",
        accessorKey: "ApprovedByName",
      },

      {
        header: "Date",
        accessorKey: "VoucherDate",
        size: "80",
      },
    ],
    []
  );
  const loadBankList = () => {
    axios
      .get(
        "/settings/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Bank",
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setBankList(response.data.data);
        } else {
          // alert("log out and log in again voucher by voucher id");
        }
      });
  };
  const loadVouchersList = () => {
    setLoadTable(false);
    axios
      .get(
        "/Vouchers/getByPurchaseTypeAndStatus?Supplier=" +
          globalUser.Parent +
          "&Status=" +
          location.state.Status +
          "&Store=" +
          (location.state.Store === undefined ||
          location.state.Store === null ||
          isNaN(location.state.Store)
            ? 0
            : location.state.Store),
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setPaymentList(response.data.data.PaymentOrders);
          setVouchersList(response.data.data.Orders);
          setLineItemsList(response.data.data.LineItems);
          setReceivedVouchersList(response.data.data.ReceivedOrders);
          setReceivedLineItemsList(response.data.data.ReceivedLineItems);
          setProformaVouchersList(response.data.data.ProformaOrders);
          setProformaLineItemsList(response.data.data.ProformaLineItems);
        } else {
          alert("log out and log in again");
        }
      })
      .then(() => {
        console.log("after loading");
        setLoadTable(true);
      });
  };
  const loadEmployeeList = () => {
    axios
      .get(
        "/employees/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Employee",
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setEmployeeList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/Vouchers/Excel", fdata, {
      headers: { "Content-Type": "application/json", token: globalToken },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(VouchersList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "vouchers");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "vouchersdata.xlsx");
  }

  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }
    setSelectedPurchaseType(location.state.Status);
    setPageHeader("Pur. " + location.state.Status);
    loadEmployeeList();
    loadBankList();
    loadVouchersList();
  }, [update, location.state]);

  return (
    <div width="100%">
      {employeeList !== undefined &&
      employeeList !== null &&
      employeeList.length > 0 &&
      bankList &&
      bankList.length > 0 ? (
        <Grid container>
          <Grid item className="hideInMobile" m={1}>
            <MaterialReactTable
              sx={{ backgroundColor: "#00f" }}
              columns={
                location.state.Status === "Return"
                  ? purchaseReturnColumns
                  : location.state.Status !== "Draft" &&
                    location.state.Status !== "Request" &&
                    location.state.Status !== "Approved"
                  ? purchaseColumns
                  : requestColumns
              }
              data={VouchersList}
              initialState={{
                density: "compact",
                pagination: {
                  pageIndex: 0,
                  pageSize: 20,
                },
              }}
              enableGlobalFilter
              enableColumnResizing
              enableRowNumbers
              renderDetailPanel={({ row }) => (
                <Grid
                  container
                  ml={2}
                  mr={4}
                  sx={{ backgroundColor: "smockwhite" }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.contrastText,
                      fontWeight: "bold",
                    }}
                  >
                    <Tooltip title="Edit">
                      {row.original.Status === "Draft" ? (
                        <IconButton>
                          <NewOrder
                            icon={<EditSharp />}
                            Type="Purchase"
                            BankList={bankList}
                            data={row.original}
                            LineItems={LineItemsList.filter(
                              (li) => li.Reference === row.original.OrderId
                            )}
                          />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <PurchaseModelHistory
                            icon={
                              <EditSharp
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                            }
                            data={row.original}
                            Proformas={proformaVouchersList.filter(
                              (pv) =>
                                parseInt(pv.SourceNo) ===
                                parseInt(row.original.OrderId)
                            )}
                            Type={location.state.Type}
                            LineItems={LineItemsList.filter(
                              (li) => li.Reference === row.original.OrderId
                            )}
                            BankList={bankList}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </IconButton>
                      )}
                    </Tooltip>
                    | Line Items
                  </Grid>
                  {LineItemsList &&
                    LineItemsList.filter(
                      (li) => li.Reference === row.original.OrderId
                    ).map((litem, index) => {
                      let shippedQty = 0;
                      receivedLineItemsList &&
                        receivedLineItemsList
                          .filter(
                            (shLine) =>
                              parseInt(shLine.SourceNo) ===
                                parseInt(row.original.OrderId) &&
                              parseInt(shLine.Item) === parseInt(litem.Item)
                          )
                          .map((shItem) => {
                            shippedQty = shippedQty + shItem.Quantity;
                          });
                      return (
                        <>
                          {index === 0 ? (
                            <>
                              <Grid
                                item
                                xs={4}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                                pl={12}
                              >
                                Item
                              </Grid>
                              <Grid
                                item
                                xs={1.5}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                                pl={7}
                              >
                                Quantity
                              </Grid>
                              <Grid
                                item
                                xs={1.5}
                                pl={7}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                              >
                                Received Qty
                              </Grid>
                              <Grid
                                item
                                xs={1.5}
                                pl={7}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                              >
                                Remaining Qty
                              </Grid>
                              <Grid
                                item
                                xs={1.5}
                                pl={7}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                              >
                                Unit Price
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                pl={12}
                                sx={{
                                  backgroundColor: theme.palette.primary.dark,
                                  color: theme.palette.primary.contrastText,
                                }}
                              >
                                Total Price
                              </Grid>
                              {/*   <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                VAT Amount
                              </Grid> */}

                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {index + 1 + "-" + litem.Name}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {shippedQty}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {(
                                  +litem.Quantity - +shippedQty
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {litem.UnitPrice?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TotalPrice?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              {/* <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TaxAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid> */}
                            </>
                          ) : (
                            <>
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {index + 1 + "-" + litem.Name}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {shippedQty}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {(
                                  +litem.Quantity - +shippedQty
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={1.5} sx={{ border: 1 }} pl={2}>
                                {litem.UnitPrice?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TotalPrice?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Grid>
                              {/*  <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.TaxAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid> */}
                            </>
                          )}
                        </>
                      );
                    })}
                  {receivedVouchersList &&
                    receivedVouchersList
                      .filter(
                        (recOrd) =>
                          parseInt(recOrd.SourceNo) ===
                          parseInt(row.original.OrderId)
                      )
                      .map((recOrd, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor:
                                  theme.palette.primary.dark /* "#1976d2" */,
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <PurchaseReceiptModel
                                  icon={
                                    <EditSharp
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        margin: 1,
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                  Type="Purchase"
                                  data={recOrd}
                                  LineItems={receivedLineItemsList.filter(
                                    (li) => li.Reference === recOrd.OrderId
                                  )}
                                  BankList={bankList}
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              </IconButton>
                              |
                              <IconButton>
                                <InvoicePreview
                                  icon={
                                    <PreviewSharp
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        margin: 1,
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                  Suppliers={[
                                    { ...recOrd, Id: recOrd.Supplier },
                                  ]}
                                  LineItems={receivedLineItemsList.filter(
                                    (li) => li.Reference === recOrd.OrderId
                                  )}
                                  data=""
                                  DocumentType={"Purchase Receipt"}
                                  Type="Invoice"
                                />
                              </IconButton>
                              |
                              {" Received Order: " +
                                (parseInt(index) + 1) +
                                "       Status: " +
                                recOrd.Status}
                            </Grid>

                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Received By:  " + recOrd.UserName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Approved By:  " + jobOrder.ApprovedByName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Assigned To: " + jobOrder.AssignedToName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Status: " + recOrd.Status}
                            </Grid>

                            {receivedLineItemsList &&
                              receivedLineItemsList
                                .filter(
                                  (li) =>
                                    parseInt(li.Reference) ===
                                    parseInt(recOrd.OrderId)
                                )
                                .map((recLitem, index) => {
                                  return (
                                    <>
                                      {index === 0 ? (
                                        <>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Item
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Received Quantity
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Unit Price
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Total Price
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            VAT Amount
                                          </Grid>

                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {index + 1 + "-" + recLitem.Name}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.Quantity.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.UnitPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.TotalPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.TaxAmount.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.Name}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.Quantity.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.UnitPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.TotalPrice?.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {recLitem.TaxAmount?.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                          </>
                        );
                      })}
                  {paymentList &&
                    paymentList
                      .filter(
                        (payOrd) =>
                          parseInt(payOrd.OrderNo) ===
                          parseInt(row.original.OrderId)
                      )
                      .map((payOrd, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: "#1976d2",
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <PaymentsModel
                                  icon={<PaymentSharp />}
                                  data=""
                                  OrderData={row.original}
                                  BankList={bankList}
                                  Type="Purchase"
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                                |
                              </IconButton>
                              {"     Payment Orders: " +
                                (parseInt(index) + 1) +
                                "       Status: " +
                                payOrd.Status}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid Date: " + payOrd.PaidDate}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid By:  " + payOrd.PaidByName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Paid To:  " + payOrd.PaidToName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Amount: " +
                                payOrd.Amount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </Grid>
                          </>
                        );
                      })}
                  {proformaVouchersList &&
                    proformaVouchersList
                      .filter(
                        (proOrd) =>
                          parseInt(proOrd.SourceNo) ===
                          parseInt(row.original.OrderId)
                      )
                      .map((proOrd, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                fontWeight: "bold",
                              }}
                            >
                              <IconButton>
                                <PurchaseProformaModel
                                  icon={
                                    <EditSharp
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        margin: 1,
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                  Type="Purchase"
                                  data={proOrd}
                                  LineItems={proformaLineItemsList.filter(
                                    (li) => li.Reference === proOrd.OrderId
                                  )}
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              </IconButton>
                              |
                              <IconButton>
                                <InvoicePreview
                                  icon={
                                    <PreviewSharp
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        margin: 1,
                                        marginRight: 4,
                                      }}
                                    />
                                  }
                                  Suppliers={[
                                    { ...proOrd, Id: proOrd.Supplier },
                                  ]}
                                  LineItems={proformaLineItemsList.filter(
                                    (li) => li.Reference === proOrd.OrderId
                                  )}
                                  data=""
                                  DocumentType={"Purchase Proforma"}
                                  Type="Invoice"
                                />
                              </IconButton>
                              |
                              {" Purchase Proforma: " +
                                (parseInt(index) + 1) +
                                "      |  Supplier: " +
                                proOrd.Name +
                                "       | Proforma Date: " +
                                proOrd.VoucherDate +
                                "       | Total Amount: " +
                                proOrd.TotalAmount?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </Grid>

                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Received By:  " + proOrd.UserName}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Approved By:  " + jobOrder.ApprovedByName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {/*  {"Assigned To: " + jobOrder.AssignedToName} */}
                            </Grid>
                            <Grid item xs={3} sx={{ border: 1 }} pl={2}>
                              {"Status: " + proOrd.Status}
                            </Grid>

                            {proformaLineItemsList &&
                              proformaLineItemsList
                                .filter(
                                  (li) =>
                                    parseInt(li.Reference) ===
                                    parseInt(proOrd.OrderId)
                                )
                                .map((proLitem, index) => {
                                  return (
                                    <>
                                      {index === 0 ? (
                                        <>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Item
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Quantity
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Unit Price
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            Total Price
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={12}
                                            style={{
                                              backgroundColor: "lightgray",
                                            }}
                                          >
                                            VAT Amount
                                          </Grid>

                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {index + 1 + "-" + proLitem.Name}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.Quantity.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.UnitPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.TotalPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.TaxAmount.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            item
                                            xs={4}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.Name}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.Quantity.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.UnitPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.TotalPrice.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ border: 1 }}
                                            pl={2}
                                          >
                                            {proLitem.TaxAmount.toLocaleString(
                                              undefined,
                                              { maximumFractionDigits: 2 }
                                            )}
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                          </>
                        );
                      })}
                </Grid>
              )}
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  {location.state.Status !== "Return" &&
                    location.state.Status !== "Proforma" &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase " + location.state.Status
                    ).length > 0 &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase " + location.state.Status
                    )[0].Security > 2 && (
                      <>
                        <Button
                          size="small"
                          variant="standard"
                          sx={{ borderRadius: "30px" }}
                          endIcon={
                            <AddSharp
                              style={{ width: "20px", height: "20px" }}
                            />
                          }
                        >
                          <NewOrder
                            icon={<div>Add</div>}
                            LineItems={[]}
                            Type="Purchase"
                            BankList={bankList}
                            Status={location.state.Status}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </Button>
                        |
                      </>
                    )}
                  {location.state.Status === "Order" &&
                    globalRoleSecurity.filter(
                      (gs) => gs.Functionality === "Purchase Direct"
                    ).length > 0 &&
                    globalRoleSecurity.filter(
                      (gs) => gs.Functionality === "Purchase Direct"
                    )[0].Security > 2 && (
                      <>
                        <Button
                          size="small"
                          variant="standard"
                          sx={{ borderRadius: "30px" }}
                          endIcon={
                            <AddSharp
                              style={{ width: "20px", height: "20px" }}
                            />
                          }
                        >
                          <NewOrder
                            icon={<div>Direct Purchase</div>}
                            LineItems={[]}
                            data={{
                              ...globalProviders[0],
                              Status: "Direct",
                              VoucherDate: format(new Date(), "yyyy-MM-dd"),
                            }}
                            Type="Purchase"
                            Status="Direct"
                            BankList={bankList}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </Button>
                        |
                      </>
                    )}
                  {location.state.Status === "Order" &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase Direct" ||
                        gs.Functionality === "Direct Receipt"
                    ).length > 0 &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase Direct" ||
                        gs.Functionality === "Direct Receipt"
                    )[0].Security > 2 && (
                      <>
                        <Button
                          size="small"
                          variant="standard"
                          sx={{ borderRadius: "30px" }}
                          endIcon={
                            <AddSharp
                              style={{ width: "20px", height: "20px" }}
                            />
                          }
                        >
                          <NewOrder
                            icon={<div>Direct Receipt</div>}
                            LineItems={[]}
                            data={{
                              ...globalProviders[0],
                              Status: "Direct",
                              VoucherDate: format(new Date(), "yyyy-MM-dd"),
                            }}
                            Type="Purchase"
                            Status="Direct Receipt"
                            BankList={bankList}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </Button>
                        |
                      </>
                    )}
                  {location.state.Status === "Order" &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase Direct" ||
                        gs.Functionality === "Direct Receipt & Payment"
                    ).length > 0 &&
                    globalRoleSecurity.filter(
                      (gs) =>
                        gs.Functionality === "Purchase Direct" ||
                        gs.Functionality === "Direct Receipt & Payment"
                    )[0].Security > 2 && (
                      <>
                        <Button
                          size="small"
                          variant="standard"
                          sx={{ borderRadius: "30px" }}
                          endIcon={
                            <AddSharp
                              style={{ width: "20px", height: "20px" }}
                            />
                          }
                        >
                          <NewOrder
                            icon={<div>Direct Receipt & Payment</div>}
                            LineItems={[]}
                            data={{
                              ...globalProviders[0],
                              Status: "Direct",
                              VoucherDate: format(new Date(), "yyyy-MM-dd"),
                            }}
                            Type="Purchase"
                            Status="Direct Receipt & Payment"
                            BankList={bankList}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </Button>
                        |
                      </>
                    )}
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <FileDownload style={{ width: "20px", height: "20px" }} />
                    }
                    type="file"
                    onClick={handleDownloadFile}
                  >
                    Download
                  </Button>
                  |
                  <Tooltip title="refresh">
                    <Button
                      size="small"
                      variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={<RefreshSharp />}
                      onClick={() => setUpdate(!update)}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
              )}
              enableRowActions
              renderRowActionMenuItems={({ row }) => [
                <Tooltip title={"Purchase " + row.original.Status}>
                  {(row.original.Status === "Draft" ||
                    row.original.Status === "Request" ||
                    row.original.Status === "Proforma") &&
                  globalRoleSecurity.filter(
                    (sec) =>
                      sec.Functionality === "Purchase " + row.original.Status
                  ).length > 0 &&
                  globalRoleSecurity.filter(
                    (sec) =>
                      sec.Functionality === "Purchase " + row.original.Status
                  )[0].Security > 1 ? (
                    <MenuItem>
                      {row.original.Status !== "Proforma" ? (
                        <NewOrder
                          BankList={bankList}
                          icon={
                            <div>
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              {"Purchase " + row.original.Status}{" "}
                            </div>
                          }
                          Type="Purchase"
                          data={row.original}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      ) : (
                        <PurchaseProformaModel
                          icon={
                            <div>
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              {"Purchase " + row.original.Status}{" "}
                            </div>
                          }
                          Type="Purchase"
                          data={row.original}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      )}
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <PurchaseModelHistory
                        icon={
                          <div>
                            <EditSharp
                              style={{
                                width: "10px",
                                height: "10px",
                                margin: 1,
                                marginRight: 4,
                              }}
                            />
                            {"Purchase " + row.original.Status}
                          </div>
                        }
                        data={row.original}
                        Proformas={proformaVouchersList.filter(
                          (pv) =>
                            parseInt(pv.SourceNo) ===
                            parseInt(row.original.OrderId)
                        )}
                        Type={location.state.Type}
                        LineItems={LineItemsList.filter(
                          (li) => li.Reference === row.original.OrderId
                        )}
                        BankList={bankList}
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </MenuItem>
                  )}
                </Tooltip>,

                row.original.Status === "Order" && (
                  <Tooltip title="Purchase Receipt">
                    <MenuItem>
                      <PurchaseReceiptModel
                        icon={
                          <div>
                            <ShoppingBag
                              style={{
                                width: "13px",
                                height: "13px",
                                margin: 1,
                                marginRight: 4,
                              }}
                            />
                            Purchase Receipt
                          </div>
                        }
                        SourceNo={row.original.OrderId}
                        data={row.original}
                        LineItems={LineItemsList.filter(
                          (li) => li.Reference === row.original.OrderId
                        )}
                        ReceivedLineItems={receivedLineItemsList.filter(
                          (reclItem) =>
                            parseInt(reclItem.SourceNo) ===
                            parseInt(row.original.OrderId)
                        )}
                        BankList={bankList}
                        Type="Purchase"
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </MenuItem>
                  </Tooltip>
                ),
                (row.original.Status === "Order" ||
                  row.original.Status === "Received") && (
                  <Tooltip title="Payment">
                    <MenuItem>
                      <PaymentsModel
                        icon={
                          <div>
                            <PaymentSharp
                              style={{
                                width: "13px",
                                height: "13px",
                                margin: 1,
                                marginRight: 4,
                              }}
                            />
                            Payment
                          </div>
                        }
                        OrderData={row.original}
                        BankList={bankList}
                        Type="Purchase"
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </MenuItem>
                  </Tooltip>
                ),

                row.original.Status === "Approved" &&
                  globalRoleSecurity.filter(
                    (sec) => sec.Functionality === "Purchase Proforma"
                  )[0].Security > 1 && (
                    <Tooltip title="Purchase Proforma">
                      <MenuItem>
                        <PurchaseProformaModel
                          icon={
                            <div>
                              <EditSharp
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  margin: 1,
                                  marginRight: 4,
                                }}
                              />
                              {"Purchase Proforma"}{" "}
                            </div>
                          }
                          Type="Purchase"
                          data={{ ...row.original }}
                          LineItems={LineItemsList.filter(
                            (li) => li.Reference === row.original.OrderId
                          )}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                      </MenuItem>
                    </Tooltip>
                  ),
                <Tooltip title="Preview">
                  <MenuItem>
                    <InvoicePreview
                      icon={
                        <div>
                          <PreviewSharp
                            style={{
                              width: "13px",
                              height: "13px",
                              margin: 1,
                              marginRight: 4,
                            }}
                          />
                          Preview
                        </div>
                      }
                      Suppliers={[
                        { ...row.original, Id: row.original.Supplier },
                      ]}
                      LineItems={LineItemsList.filter(
                        (li) => li.Reference === row.original.OrderId
                      )}
                      DocumentType={"Purchase " + row.original.Status}
                      Type="Invoice"
                    />
                  </MenuItem>
                </Tooltip>,
              ]}
            ></MaterialReactTable>
          </Grid>
          <Grid item xs={12} mt={1} className="hideInFull">
            <Box
              sx={{
                width: "100%",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              {location.state.Status !== "Return" &&
                location.state.Status !== "Proforma" &&
                globalRoleSecurity.filter(
                  (gs) =>
                    gs.Functionality === "Purchase " + location.state.Status
                ).length > 0 &&
                globalRoleSecurity.filter(
                  (gs) =>
                    gs.Functionality === "Purchase " + location.state.Status
                )[0].Security > 2 && (
                  <>
                    <Button
                      size="small"
                      variant="standard"
                      endIcon={<AddSharp />}
                    >
                      <NewOrder
                        icon={<div>Add</div>}
                        LineItems={[]}
                        Type="Purchase"
                        BankList={bankList}
                        Status={location.state.Status}
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </Button>
                    |
                  </>
                )}
              {location.state.Status === "Order" &&
                globalRoleSecurity.filter(
                  (gs) => gs.Functionality === "Purchase Direct"
                ).length > 0 &&
                globalRoleSecurity.filter(
                  (gs) => gs.Functionality === "Purchase Direct"
                )[0].Security > 2 && (
                  <>
                    <Button
                      size="small"
                      variant="standard"
                      endIcon={
                        <AddSharp style={{ width: "20px", height: "20px" }} />
                      }
                    >
                      Direct Order And Receipt
                      <NewOrder
                        icon={<div></div>}
                        LineItems={[]}
                        data={{
                          ...globalProviders[0],
                          Status: "Direct",
                          VoucherDate: format(new Date(), "yyyy-MM-dd"),
                        }}
                        Type="Purchase"
                        Status="Direct"
                        BankList={bankList}
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                    </Button>
                    |
                  </>
                )}
              <Button
                size="small"
                variant="standard"
                endIcon={
                  <FileDownload style={{ width: "20px", height: "20px" }} />
                }
                type="file"
                onClick={handleDownloadFile}
              >
                Download
              </Button>
              |
              <Tooltip title="refresh">
                <Button
                  size="small"
                  variant="standard"
                  endIcon={<RefreshSharp />}
                  onClick={() => setUpdate(!update)}
                >
                  Refresh
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          {!(VouchersList === undefined) &&
            VouchersList.length > 0 &&
            VouchersList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5.5} m={1} className="hideInFull">
                  <Card sx={{ borderRadius: 10, boxShadow: 3, width: "100%" }}>
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={"Supplier: " + item.Name} //.substring(0, 20)}
                      subheader={
                        "Total Amount: " +
                        item.TotalAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                        Status: {item.Status}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Store: {item.StoreName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Date: {item.VoucherDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Amount:{" "}
                        {item.Amount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography variant="body2" align="left">
                        VAT Amount:{" "}
                        {item.VATAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Total Amount:{" "}
                        {item.TotalAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      <Stack direction="row">
                        <Tooltip title={"Purchase " + item.Status}>
                          {(item.Status === "Draft" ||
                            item.Status === "Request" ||
                            item.Status === "Proforma" ||
                            item.Status === "Order") &&
                          globalRoleSecurity.filter(
                            (sec) =>
                              sec.Functionality === "Purchase " + item.Status
                          ).length > 0 &&
                          globalRoleSecurity.filter(
                            (sec) =>
                              sec.Functionality === "Purchase " + item.Status
                          )[0].Security > 1 ? (
                            <IconButton>
                              {item.Status !== "Proforma" ? (
                                <NewOrder
                                  icon={
                                    <div>
                                      <EditSharp
                                        sx={{
                                          color:
                                            theme.palette.primary.contrastText,
                                          width: "13px",
                                          height: "13px",
                                          paddingRight: 2,
                                        }}
                                      />
                                      |
                                    </div>
                                  }
                                  Type="Purchase"
                                  data={item}
                                  LineItems={LineItemsList.filter(
                                    (li) => li.Reference === item.OrderId
                                  )}
                                  BankList={bankList}
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              ) : (
                                <PurchaseProformaModel
                                  icon={
                                    <div>
                                      <EditSharp
                                        sx={{
                                          color:
                                            theme.palette.primary.contrastText,
                                          width: "13px",
                                          height: "13px",
                                          paddingRight: 2,
                                        }}
                                      />
                                      |
                                    </div>
                                  }
                                  Type="Purchase"
                                  data={item}
                                  LineItems={LineItemsList.filter(
                                    (li) => li.Reference === item.OrderId
                                  )}
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              )}
                            </IconButton>
                          ) : (
                            <IconButton>
                              <PurchaseModelHistory
                                icon={
                                  <div>
                                    <EditSharp
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                        width: "13px",
                                        height: "13px",
                                        paddingRight: 2,
                                      }}
                                      BankList={props.BankList}
                                    />
                                    |
                                  </div>
                                }
                                data={item}
                                Proformas={proformaVouchersList.filter(
                                  (pv) =>
                                    parseInt(pv.SourceNo) ===
                                    parseInt(item.OrderId)
                                )}
                                Type={location.state.Type}
                                LineItems={LineItemsList.filter(
                                  (li) => li.Reference === item.OrderId
                                )}
                                BankList={bankList}
                                ParentUpdate={update}
                                setParentUpdate={setUpdate}
                              />
                            </IconButton>
                          )}
                        </Tooltip>

                        {item.Status === "Order" && (
                          <Tooltip title="Purchase Receipt">
                            <IconButton>
                              <PurchaseReceiptModel
                                icon={
                                  <div>
                                    <ShoppingBag
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                        width: "13px",
                                        height: "13px",
                                        paddingRight: 2,
                                      }}
                                    />
                                    |
                                  </div>
                                }
                                Type="Purchase"
                                SourceNo={item.OrderId}
                                data={item}
                                LineItems={LineItemsList.filter(
                                  (li) => li.Reference === item.OrderId
                                )}
                                ReceivedLineItems={receivedLineItemsList.filter(
                                  (reclItem) =>
                                    parseInt(reclItem.SourceNo) ===
                                    parseInt(item.OrderId)
                                )}
                                BankList={bankList}
                                ParentUpdate={update}
                                setParentUpdate={setUpdate}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(item.Status === "Order" ||
                          item.Status === "Received") && (
                          <Tooltip title="Payment">
                            <IconButton>
                              <PaymentsModel
                                icon={
                                  <div>
                                    <PaymentSharp
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                        width: "13px",
                                        height: "13px",
                                        paddingRight: 2,
                                      }}
                                    />
                                    |
                                  </div>
                                }
                                OrderData={item}
                                BankList={bankList}
                                Type="Purchase"
                                ParentUpdate={update}
                                setParentUpdate={setUpdate}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {item.Status === "Approved" &&
                          globalRoleSecurity.filter(
                            (sec) => sec.Functionality === "Purchase Proforma"
                          )[0].Security > 1 && (
                            <Tooltip title="Purchase Proforma">
                              <IconButton>
                                <PurchaseProformaModel
                                  icon={
                                    <div>
                                      <EditSharp
                                        sx={{
                                          color:
                                            theme.palette.primary.contrastText,
                                          width: "13px",
                                          height: "13px",
                                        }}
                                      />
                                      |
                                    </div>
                                  }
                                  Type="Purchase"
                                  data={{ ...item }}
                                  LineItems={LineItemsList.filter(
                                    (li) => li.Reference === item.OrderId
                                  )}
                                  ParentUpdate={update}
                                  setParentUpdate={setUpdate}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        <Tooltip title="Preview">
                          <IconButton>
                            <InvoicePreview
                              icon={
                                <PreviewSharp
                                  sx={{
                                    color: theme.palette.primary.contrastText,
                                    width: "13px",
                                    height: "13px",
                                  }}
                                />
                              }
                              Suppliers={[{ ...item, Id: item.Supplier }]}
                              LineItems={LineItemsList.filter(
                                (li) => li.Reference === item.OrderId
                              )}
                              DocumentType={"Purchase " + item.Status}
                              Type="Invoice"
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <div>There is not employee in this company</div>
      )}
    </div>
  );
}

export default PurchaseVouchersComp;
