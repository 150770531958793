import "./App.css";

import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Stack,
  Badge,
  Divider,
  Avatar,
  SwipeableDrawer,
  colors,
  InputAdornment,
} from "@mui/material";
import {
  EvStationSharp,
  DraftsSharp,
  RequestQuoteSharp,
  ApprovalSharp,
  WebAssetSharp,
  RequestQuote,
  ReceiptSharp,
  StoreSharp,
  Drafts,
  LocalShippingOutlined,
  AssignmentReturnOutlined,
  DocumentScanner,
  PeopleOutline,
  PeopleAlt,
  Inventory,
  WarehouseOutlined,
  CategoryOutlined,
  Language,
  AssessmentOutlined,
  SettingsOutlined,
  VpnLockOutlined,
  LogoutOutlined,
  ManageAccountsOutlined,
  MessageOutlined,
  CategoryRounded,
  KeyboardReturnSharp,
  KeyboardReturnOutlined,
  DocumentScannerOutlined,
  LegendToggleSharp,
  AccountTreeSharp,
  SettingsSuggestSharp,
  ClosedCaptionDisabledSharp,
  Settings,
  SettingsApplications,
} from "@mui/icons-material";
import {
  styled,
  alpha,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import StockBalancesComp from "./Components/Inventory/StockBalancesComp";
import ItemsComp from "./Components/Inventory/ItemsComp";
import ItemCategoriesComp from "./Components/Inventory/ItemCategoriesComp";
import SuppliersComp from "./Components/Purchase/SuppliersComp";
import ComstomersComp from "./Components/Sales/CustomersComp";
import CustomersCompForApproval from "./Components/Sales/CustomersCompForApproval";

import UsersComp from "./Components/Security/UsersComp";
import ProductDetails from "./Components/Products/ProductDetails";
import LandingSalesComp from "./Components/Sales/LandingSalesComp";
import LandingPurchaseComp from "./Components/Purchase/LandingPurchaseComp";
import ReportInterface from "./Components/Reports/ReportInterface";
import LogInModel from "./Components/Security/LogInModel";
import Register from "./Components/Security/Register";
import SecurityComp from "./Components/Security/SecurityComp";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import MenuIcon from "@mui/icons-material/Menu";

import { ShoppingCartContext } from "./Components/Context/ShoppingCartContext";
import { Login } from "@mui/icons-material";
import HomeComp from "./Components/HomeComp";
import GMaps from "./Components/GMaps";
import NewsComp from "./Components/News/NewsHomeComp";
import SingleStory from "./Components/News/NewsSingleComp";
import EditStory from "./Components/News/NewsEditComp";
import StoresComp from "./Components/Inventory/StoresComp";
import PaymentsComp from "./Components/PaymentsComp";
import SalesRoutesComp from "./Components/Sales/SalesRoutesComp";
import NewOrder from "./Components/Products/NewOrder";
import SalesNewOrder from "./Components/Sales/SalesNewOrder";
import JobOrdersComp from "./Components/Manufacturing/JobOrdersComp";
import BOMModel from "./Components/Manufacturing/BOMModel";
import ItemJournalComp from "./Components/Inventory/ItemJournalComp";
import SalesVouchersComp from "./Components/Sales/SalesVouchersComp";
import PurchaseVouchersComp from "./Components/Purchase/PurchaseVouchersComp";
import AboutusComp from "./Components/AboutusComp";
import SettingComp from "./Components/SettingComp";
import ItemTransferComp from "./Components/Inventory/ItemTransferComp";
import ChartOfAccountComp from "./Components/Finance/ChartOfAccountComp";
import ProductSetupComp from "./Components/Finance/ProductSetupComp";
import BusinessSetupComp from "./Components/Finance/BusinessSetupComp";
import GeneralLedgerEntriesComp from "./Components/Finance/GeneralLedgerEntriesComp";
import GeneralJournalModel from "./Components/Finance/GeneralJournalModel";
import FixedAssetsComp from "./Components/Finance/FixedAssetsComp";
import FixedAssetSetupsComp from "./Components/Finance/FixedAssetSetupsComp";
import PurchaseSalesSetupModel from "./Components/Security/PurchaseSalesSetupModel";
import UserRegisterComp from "./Components/Security/UserRegisterComp";
import UserAccountChange from "./Components/Security/UserAccountChange";
import GeneralJournalComp from "./Components/Finance/GeneralJournalComp";

import PurchaseProformaComp from "./Components/Purchase/PurchaseProformaComp";
import ReceptionComp from "./Components/Hospital/ReceptonComp";
import TriageComp from "./Components/Hospital/TriageComp";
import DiagnosisComp from "./Components/Hospital/DiagnosisComp";
import LaboratoryComp from "./Components/Hospital/LaboratoryComp";
import ImagingComp from "./Components/Hospital/ImagingComp";
import CashControlComp from "./Components/Hospital/CashControlComp";
import MessagesModel from "./Components/Security/MessagesModel";
import { red } from "@mui/material/colors";
import PurchaseHistoryComp from "./Components/Purchase/PurchaseHistoryComp";
//Default
const THEME0 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
});
//Orange Shades and White
const THEME1 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.orange[500],
      contrastText: colors.common.white,
    },
  },
});
const THEME2 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.blue[900],
    },
  },
});
//Teal and White
const THEME3 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.teal[300],
      contrastText: colors.common.white,
    },
  },
});
//Dark Grey and Yellow-Green
const THEME4 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.grey[500],
      contrastText: colors.yellow[500],
    },
  },
});
//Blue Shades and White
const THEME5 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.lightBlue[500],
      contrastText: colors.common.white,
    },
  },
});
//Pale Pink and Navy Blue
const THEME6 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.lime[500],
      contrastText: colors.blue[500],
    },
  },
});
//Black and Neon Blue
const THEME7 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#454545",
    },
  },
});
//Pastel Purple and Neutral Accents
const THEME8 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.purple[200],
      contrastText: colors.common.white,
    },
  },
});

//White and Lime Green
const THEME9 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: colors.common.white,
      contrastText: colors.lightGreen[500],
    },
  },
});
//Beige and Dark Grey
const THEME10 = createTheme({
  typography: {
    fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#D4B996", //A67B5B
      contrastText: colors.common.white,
    },
  },
});
//Dark Green, Ivory, and Yellow
//White  and Black

//Navy Blue and Electric Blue
//Classic Blue, Turquoise, and Gold
//Dark Royal Blue and Gold

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Languegs = [
  {
    value: "amh",
    label: "Amharic",
  },
  {
    value: "en",
    label: "English",
  },
  {
    value: "oro",
    label: "Afaan Oromoo",
  },
  {
    value: "tig",
    label: "Tigiregna",
  },
  {
    value: "som",
    label: "Somaligna",
  },
  {
    value: "afar",
    label: "Afaregna",
  },
];

function App() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  //UserProfile
  const [anchorUserPEl, setAnchorUserPEl] = useState(null);
  const UserPOpen = Boolean(anchorUserPEl);
  const handleUserPClick = (event) => {
    setAnchorUserPEl(event.currentTarget);
  };
  const handleUserPClose = () => {
    setAnchorUserPEl(null);
    setOpenDrawer(false);
  };
  //Sales
  const [anchorSalesEl, setAnchorSalesEl] = useState(null);
  const SalesOpen = Boolean(anchorSalesEl);
  const handleSalesClick = (event) => {
    setAnchorSalesEl(event.currentTarget);
  };
  const handleSalesClose = () => {
    setAnchorSalesEl(null);
    setOpenDrawer(false);
  };
  //Purchase
  const [anchorPurchaseEl, setAnchorPurchaseEl] = useState(null);
  const purchaseOpen = Boolean(anchorPurchaseEl);
  const handlePurchaseClick = (event) => {
    setAnchorPurchaseEl(event.currentTarget);
  };
  const handlePurchaseClose = () => {
    setAnchorPurchaseEl(null);
    setOpenDrawer(false);
  };
  //Inventory
  const [anchorInventoryEl, setAnchorInventoryEl] = useState(null);
  const InventoryOpen = Boolean(anchorInventoryEl);
  const handleInventoryClick = (event) => {
    setAnchorInventoryEl(event.currentTarget);
  };
  const handleInventoryClose = () => {
    setAnchorInventoryEl(null);
    setOpenDrawer(false);
  };
  //Admins
  const [anchorAdminEl, setAnchorAdminEl] = useState(null);
  const AdminOpen = Boolean(anchorAdminEl);
  const handleAdminClick = (event) => {
    setAnchorAdminEl(event.currentTarget);
  };
  const handleAdminClose = () => {
    setAnchorAdminEl(null);
    setOpenDrawer(false);
  };
  //Manufacturing
  const [anchorJobEl, setAnchorJobEl] = useState(null);
  const JobOpen = Boolean(anchorJobEl);
  const handleJobClick = (event) => {
    setAnchorJobEl(event.currentTarget);
  };
  const handleJobClose = () => {
    setAnchorJobEl(null);
    setOpenDrawer(false);
  };
  //Finance
  const [anchorFinanceEl, setAnchorFinanceEl] = useState(null);
  const financeOpen = Boolean(anchorFinanceEl);
  const handleFinanceClick = (event) => {
    setAnchorFinanceEl(event.currentTarget);
  };
  const handleFinanceClose = () => {
    setAnchorFinanceEl(null);
    setOpenDrawer(false);
  };
  //Hospital
  const [anchorHospitalEl, setAnchorHospitalEl] = useState(null);
  const hospitalOpen = Boolean(anchorHospitalEl);
  const handleHospitalClick = (event) => {
    setAnchorHospitalEl(event.currentTarget);
  };
  const handleHospitalClose = () => {
    setAnchorHospitalEl(null);
    setOpenDrawer(false);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const {
    selectedItems,
    setGlobalSupplier,
    pageHeader,
    setSelectedItems,
    globalUser,
    setGlobalUser,
    globalRoleSecurity,
    setGlobalRoleSecurity,
    setPageHeader,
    selectedSalesType,
    selectedPurchaseType,
    selectedLanguage,
    setSelectedLanguage,
  } = useContext(ShoppingCartContext);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <ThemeProvider
      theme={
        globalUser
          ? globalUser.Theme === "THEME1"
            ? THEME1
            : globalUser.Theme === "THEME2"
            ? THEME2
            : globalUser.Theme === "THEME3"
            ? THEME3
            : globalUser.Theme === "THEME4"
            ? THEME4
            : globalUser.Theme === "THEME5"
            ? THEME5
            : globalUser.Theme === "THEME6"
            ? THEME6
            : globalUser.Theme === "THEME7"
            ? THEME7
            : globalUser.Theme === "THEME8"
            ? THEME8
            : globalUser.Theme === "THEME9"
            ? THEME9
            : globalUser.Theme === "THEME10"
            ? THEME10
            : THEME0
          : THEME0
      }
    >
      <BrowserRouter>
        <AppBar position="static">
          <Toolbar edge="start" variant="dense">
            <IconButton
              sx={{ color: theme.palette.primary.contrastText }}
              component={Link}
              to="/home"
            >
              <Avatar src="/Images/Logos/default.png" mr={6} />
              <span
                style={{
                  padding: "0",
                  marginLeft: 6,
                  fontSize: 12,
                  fontWeight: "bold",
                  color: theme.palette.primary.contrastText,
                }}
              >
                {globalUser ? globalUser.ParentName : "ARMA IT Solution"}
                <br></br>

                <span style={{ fontWeight: "normal" }}>
                  {" "}
                  {pageHeader.length > 0 ? pageHeader : ""}
                </span>
              </span>
            </IconButton>
            <Typography
              variant="body1"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Button component={Link} to={"/News"} color="inherit">
                {t("news")}
              </Button>
              |
              <Button component={Link} to={"/AboutUs"} color="inherit">
                {t("about us")}
              </Button>
              |
              {!(globalUser === null) ? (
                <>
                  {/*  {globalRoleSecurity.filter(
                    (sec) => sec.Department === "Job Order" && sec.Security > 0
                  ).length > 0 && (
                    <>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          JobOpen ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={JobOpen ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={(e) => handleJobClick(e)}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("production")}
                      </Button>
                      <StyledMenu
                        backgroundColor="black"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorJobEl}
                        open={JobOpen}
                        onClose={handleJobClose}
                      >
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Job Request" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/JobsOrder"}
                            state={{ Status: "Request" }}
                            color="inherit"
                            disableRipple
                            onClick={handleJobClose}
                          >
                            <EvStationSharp />
                            {t("Job Request")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Job Approved" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/JobsOrder"}
                            state={{ Status: "Approved" }}
                            color="inherit"
                            disableRipple
                            onClick={handleJobClose}
                          >
                            <EvStationSharp />
                            {t("Job Approved")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Job Completed" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/JobsOrder"}
                            state={{ Status: "Completed" }}
                            color="inherit"
                            disableRipple
                            onClick={handleJobClose}
                          >
                            <EvStationSharp />
                            {t("Job Completed")}
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                          component={Link}
                          to={"/ItemJournal"}
                          state={{ Status: "Completed" }}
                          color="inherit"
                          disableRipple
                          onClick={handleJobClose}
                        >
                          <EvStationSharp />
                          {t("Consumption Journal")}
                        </MenuItem>
                        <MenuItem
                          component={Link}
                          to={"/ItemJournal"}
                          state={{ Status: "Output" }}
                          color="inherit"
                          disableRipple
                          onClick={handleJobClose}
                        >
                          <EvStationSharp />
                          {t("Output Journal")}
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem color="inherit" disableRipple>
                          <BOMModel
                            Product=""
                            icon={
                              <div>
                                <Settings />
                                {t("BOM Setting")}
                              </div>
                            }
                          />
                        </MenuItem>
                      </StyledMenu>
                      |
                    </>
                  )} */}
                  {/*  <Button
                    id="demo-customized-button"
                    aria-controls={
                      financeOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={financeOpen ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleFinanceClick(e)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {t("finance")}
                  </Button>
                  <StyledMenu
                    backgroundColor="black"
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorFinanceEl}
                    open={financeOpen}
                    onClose={handleFinanceClose}
                  >
                    <MenuItem
                      component={Link}
                      to={"/GeneralJournalComp"}
                      state={{ Type: "General" }}
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        handleFinanceClose();
                      }}
                    >
                      <DocumentScannerOutlined />
                      General Journal
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/GeneralLedgerEntries"}
                      state={{ Status: "Request" }}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <LegendToggleSharp />
                      {t("General Ledger Entries")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      component={Link}
                      to={"/FixedAssetsList"}
                      state={{ Status: "Request" }}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <WebAssetSharp />
                      {t("Fixed Assets")}
                    </MenuItem>

                    <MenuItem
                      component={Link}
                      to={"/GeneralJournalComp"}
                      state={{ Type: "Closing" }}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <ClosedCaptionDisabledSharp />
                      {t("Closing Journal")}
                    </MenuItem>

                    <MenuItem
                      component={Link}
                      to={"/ChartofAccounts"}
                      state={{ Status: "Request" }}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <AccountTreeSharp />
                      {t("Chart of Accounts")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      component={Link}
                      to={"/ProductPostingSetup"}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <SettingsSuggestSharp />
                      {t("Product Posting Setup")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/BusinessPostingSetup"}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <SettingsSuggestSharp />
                      {t("Business Posting Setup")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/FAPostingSetup"}
                      color="inherit"
                      disableRipple
                      onClick={handleFinanceClose}
                    >
                      <SettingsSuggestSharp />
                      {t("FA Posting Setup")}
                    </MenuItem>
                  </StyledMenu>
                  | */}
                  {/*  <Button
                    id="demo-customized-button"
                    aria-controls={
                      hospitalOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={hospitalOpen ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleHospitalClick(e)}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {t("hospital")}
                  </Button>
                  <StyledMenu
                    backgroundColor="black"
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorHospitalEl}
                    open={hospitalOpen}
                    onClose={handleHospitalClose}
                  >
                    <MenuItem
                      component={Link}
                      to={"/ReceptionComp"}
                      color="inherit"
                      disableRipple
                      onClick={() => {
                        handleHospitalClose();
                      }}
                    >
                      <DocumentScannerOutlined />
                      Reception
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/TriageComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <LegendToggleSharp />
                      {t("Triage")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      component={Link}
                      to={"/DiagnosisComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <WebAssetSharp />
                      {t("Diagnosis")}
                    </MenuItem>

                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      component={Link}
                      to={"/LaboratoryComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <WebAssetSharp />
                      {t("Laboratory")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/ImagingComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <WebAssetSharp />
                      {t("Imaging")}
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={"/IPDComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <WebAssetSharp />
                      {t("IPD")}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      component={Link}
                      to={"/CashControlComp"}
                      color="inherit"
                      disableRipple
                      onClick={handleHospitalClose}
                    >
                      <WebAssetSharp />
                      {t("Casher")}
                    </MenuItem>
                  </StyledMenu>
                  | */}
                  {globalRoleSecurity.filter(
                    (sec) => sec.Department === "Inventory" && sec.Security > 0
                  ).length > 0 && (
                    <>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          InventoryOpen ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={InventoryOpen ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={(e) => handleInventoryClick(e)}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("inventory")}
                      </Button>
                      <StyledMenu
                        backgroundColor="black"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorInventoryEl}
                        open={InventoryOpen}
                        onClose={handleInventoryClose}
                      >
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Issue Request" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemJournals"}
                            state={{
                              Status: "Request",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Issue Request" &&
                                  sec.Security > 0
                              )[0].Store,
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Issue Request
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Issue Approval" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemJournals"}
                            state={{
                              Status: "Approved",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Issue Approval" &&
                                  sec.Security > 0
                              )[0].Store,
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Issue Approval
                          </MenuItem>
                        )}

                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Issued" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemJournals"}
                            state={{
                              Status: "Issued",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Issued" &&
                                  sec.Security > 0
                              )[0].Store,
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Issued
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Issue Approval" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemJournals"}
                            state={{
                              Status: "Cleared",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Issue Approval" &&
                                  sec.Security > 0
                              )[0].Store,
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Issue Cleared
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Issue Return" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemJournals"}
                            state={{
                              Status: "Return",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Issue Return" &&
                                  sec.Security > 0
                              )[0].Store,
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Issue Returned
                          </MenuItem>
                        )}

                        <Divider sx={{ my: 0.5 }} />
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Request" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Request" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Request")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Approved" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Approved" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Approved")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Issue" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Issued" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Issue")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Received" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Received" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Received")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Direct" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Direct" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Direct")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Transfer Cleared" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/TransferOrder"}
                            state={{ Status: "Cleared" }}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            {t("Transfer Cleared")}
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />

                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Physical Count" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PhysicalCount"}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Physical Count
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Cost Adjustment" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/CostAdjustment"}
                            color="inherit"
                            disableRipple
                            onClick={handleInventoryClose}
                          >
                            <EvStationSharp />
                            Cost Adjustment
                          </MenuItem>
                        )}
                      </StyledMenu>
                      |
                    </>
                  )}
                  {globalRoleSecurity.filter(
                    (sec) => sec.Department === "Sales" && sec.Security > 0
                  ).length > 0 && (
                    <>
                      <Badge
                        badgeContent={
                          selectedItems.filter((it) => it.Type === "Sales")
                            .length
                        }
                        color="secondary"
                      >
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            SalesOpen ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={SalesOpen ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleSalesClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          {t("sale")}
                        </Button>
                      </Badge>
                      <StyledMenu
                        backgroundColor="black"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorSalesEl}
                        open={SalesOpen}
                        onClose={handleSalesClose}
                      >
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Request" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <MenuItem color="inherit">
                              <Badge
                                badgeContent={
                                  selectedItems.filter(
                                    (it) => it.Type === "Sales"
                                  ).length
                                }
                                color="secondary"
                              >
                                <SalesNewOrder
                                  data={null}
                                  Type="Sales"
                                  Status={selectedSalesType}
                                  icon={
                                    <Stack direction="row">
                                      <AddShoppingCartIcon
                                        style={{
                                          marginRight: 8,
                                          marginLeft: 8,
                                        }}
                                      />
                                      <span>New Order</span>
                                    </Stack>
                                  }
                                  Title="title"
                                />
                              </Badge>{" "}
                            </MenuItem>

                            <MenuItem
                              component={Link}
                              to={"/SalesProducts"}
                              color="inherit"
                              disableRipple
                              onClick={handleSalesClose}
                            >
                              <EvStationSharp />
                              {t("Sales Products")}
                            </MenuItem>

                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/SalesVouchersList"}
                              state={{
                                Type: "Sales",
                                Store: globalRoleSecurity.filter(
                                  (sec) =>
                                    sec.Functionality === "Sales Request" &&
                                    sec.Security > 0
                                )[0].Store,
                                Status: "Draft",
                              }}
                              color="inherit"
                              disableRipple
                              onClick={handleSalesClose}
                            >
                              <DraftsSharp />
                              {t("Draft")}
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              to={"/SalesVouchersList"}
                              state={{
                                Type: "Sales",
                                Store: globalRoleSecurity.filter(
                                  (sec) =>
                                    sec.Functionality === "Sales Request" &&
                                    sec.Security > 0
                                )[0].Store,
                                Status: "Proforma",
                              }}
                              color="inherit"
                              disableRipple
                              onClick={handleSalesClose}
                            >
                              <RequestQuoteSharp />
                              {t("Proforma")}
                            </MenuItem>
                          </>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Order" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/SalesVouchersList"}
                            state={{
                              Type: "Sales",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Sales Order" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Order",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleSalesClose}
                          >
                            <EvStationSharp />
                            {t("Orders")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Delivery" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/SalesVouchersList"}
                            state={{
                              Type: "Sales",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Sales Delivery" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Delivery",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleSalesClose}
                          >
                            <LocalShippingOutlined />
                            {t("Delivery")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Return" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/SalesVouchersList"}
                            state={{
                              Type: "Sales",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Sales Return" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Return",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleSalesClose}
                          >
                            <EvStationSharp />
                            {t("Return")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Cleared" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/SalesVouchersList"}
                            state={{
                              Type: "Sales",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Sales Cleared" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Cleared",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handleSalesClose}
                          >
                            <EvStationSharp />
                            {t("Cleared")}
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Payment Received" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Payments"}
                            state={{ Type: "Sales" }}
                            color="inherit"
                            disableRipple
                            onClick={handleSalesClose}
                          >
                            <EvStationSharp />
                            {t("Payment")}
                          </MenuItem>
                        )}{" "}
                        <MenuItem
                          component={Link}
                          to={"/Payments"}
                          state={{ Type: "Sales" }}
                          color="inherit"
                          disableRipple
                          onClick={handleSalesClose}
                        >
                          <EvStationSharp />
                          {t("Payment")}
                        </MenuItem>
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales History" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/PurchaseHistory"}
                              state={{ Type: "Sales" }}
                              color="inherit"
                              disableRipple
                              onClick={handleSalesClose}
                            >
                              <EvStationSharp />
                              {t("Sales History")}
                            </MenuItem>
                          </>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Sales Setup" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              /* component={Link}
                        to={"/SalesSeup"}
                        state={{ }} */
                              color="inherit"
                              disableRipple
                            >
                              <PurchaseSalesSetupModel
                                icon={
                                  <div>
                                    <Settings />
                                    {t("Sales Setup")}
                                  </div>
                                }
                                Type="Sales"
                              />
                            </MenuItem>
                          </>
                        )}
                      </StyledMenu>
                      |
                    </>
                  )}
                  {globalRoleSecurity.filter(
                    (sec) => sec.Department === "Purchase" && sec.Security > 0
                  ).length > 0 && (
                    <>
                      <Badge
                        badgeContent={
                          selectedItems?.filter((it) => it.Type === "Purchase")
                            .length
                        }
                        color="secondary"
                      >
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            purchaseOpen ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={purchaseOpen ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handlePurchaseClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          {t("purchase")}
                        </Button>
                      </Badge>
                      <StyledMenu
                        backgroundColor="black"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorPurchaseEl}
                        open={purchaseOpen}
                        onClose={handlePurchaseClose}
                      >
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Request" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <MenuItem
                              color="inherit"
                              disableRipple
                              style={{ alignItem: "left" }}
                            >
                              <Badge
                                badgeContent={
                                  selectedItems.filter(
                                    (it) => it.Type === "Purchase"
                                  ).length
                                }
                                color="secondary"
                              >
                                <NewOrder
                                  data={null}
                                  Type="Purchase"
                                  Status={selectedPurchaseType}
                                  icon={
                                    <Stack direction="row">
                                      <AddShoppingCartIcon />
                                      New Order
                                    </Stack>
                                  }
                                />
                              </Badge>{" "}
                            </MenuItem>

                            <MenuItem
                              component={Link}
                              to={"/PurchaseProducts"}
                              color="inherit"
                              disableRipple
                              onClick={handlePurchaseClose}
                            >
                              <RequestQuote />
                              {t("Purchase Products")}
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/PurchaseVouchersList"}
                              state={{
                                Type: "Purchase",
                                Store: globalRoleSecurity.filter(
                                  (sec) =>
                                    sec.Functionality === "Purchase Request" &&
                                    sec.Security > 0
                                )[0].Store,
                                Status: "Draft",
                              }}
                              color="inherit"
                              disableRipple
                              onClick={handlePurchaseClose}
                            >
                              <DraftsSharp />
                              {t("Draft")}
                            </MenuItem>

                            <MenuItem
                              component={Link}
                              to={"/PurchaseVouchersList"}
                              state={{
                                Type: "Purchase",
                                Store: globalRoleSecurity.filter(
                                  (sec) =>
                                    sec.Functionality === "Purchase Request" &&
                                    sec.Security > 0
                                )[0].Store,
                                Status: "Request",
                              }}
                              color="inherit"
                              disableRipple
                              onClick={handlePurchaseClose}
                            >
                              <RequestQuoteSharp />
                              {t("Request")}
                            </MenuItem>
                          </>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Proforma" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseVouchersList"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Proforma" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Approved",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <ApprovalSharp />
                            {t("Approved Request")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Proforma" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseProformaComp"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Proforma" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Proforma",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <ApprovalSharp />
                            {t("Purchase Proforma")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Order" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseVouchersList"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Order" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Order",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <ApprovalSharp />
                            {t("Orders")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Receipt" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseProformaComp"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Receipt" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Received",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <LocalShippingOutlined />
                            {t("Received")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Return" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseVouchersList"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Return" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Return",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <EvStationSharp />
                            {t("Return")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Cleared" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/PurchaseVouchersList"}
                            state={{
                              Type: "Purchase",
                              Store: globalRoleSecurity.filter(
                                (sec) =>
                                  sec.Functionality === "Purchase Cleared" &&
                                  sec.Security > 0
                              )[0].Store,
                              Status: "Cleared",
                            }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <EvStationSharp />
                            {t("Cleared")}
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Payment" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Payments"}
                            state={{ Type: "Purchase" }}
                            color="inherit"
                            disableRipple
                            onClick={handlePurchaseClose}
                          >
                            <EvStationSharp />
                            {t("Payment")}
                          </MenuItem>
                        )}

                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase History" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/PurchaseHistory"}
                              state={{ Type: "Purchase" }}
                              color="inherit"
                              disableRipple
                              onClick={handlePurchaseClose}
                            >
                              <EvStationSharp />
                              {t("Purchase History")}
                            </MenuItem>
                          </>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Purchase Setup" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              /*  component={Link}
                        to={"/PurchaseSeup"}
                        state={{ Type: "Purchase"}} */
                              color="inherit"
                              disableRipple
                            >
                              <PurchaseSalesSetupModel
                                icon={
                                  <div>
                                    <Settings />
                                    {t("Purchase Setup")}
                                  </div>
                                }
                                Type="Purchase"
                              />
                            </MenuItem>
                          </>
                        )}
                      </StyledMenu>
                      |
                    </>
                  )}
                  {globalRoleSecurity.filter(
                    (sec) => sec.Department === "Admin" && sec.Security > 0
                  ).length > 0 && (
                    <>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          AdminOpen ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={AdminOpen ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleAdminClick}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {t("admins")}
                      </Button>
                      <StyledMenu
                        backgroundColor="black"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorAdminEl}
                        open={AdminOpen}
                        onClose={handleAdminClose}
                      >
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Users" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Users"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <PeopleOutline />
                            {t("Users")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Customers" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Customers"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <PeopleAlt />
                            {t("Customers")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Suppliers" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Suppliers"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <PeopleAlt />
                            {t("Suppliers")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Prospects" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/CustomersForApproval"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <EvStationSharp />
                            {t("Prospects")}
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Stock Balance" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/StockBalances"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <Inventory />
                            Stock Balances
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Stores" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Stores"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <WarehouseOutlined />
                            Stores
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Items" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Items"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <CategoryRounded />
                            Items
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Items" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/SubItems"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <CategoryRounded />
                            Sub Items
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Item Category" &&
                            sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/ItemCategories"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <CategoryOutlined />
                            Item Categories
                          </MenuItem>
                        )}
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                          component={Link}
                          to={"/Reports"}
                          color="inherit"
                          disableRipple
                          onClick={handleAdminClose}
                        >
                          <AssessmentOutlined />
                          {t("Reports")}
                        </MenuItem>
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Setting" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Setting"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <SettingsOutlined />
                            {t("Setting")}
                          </MenuItem>
                        )}
                        {globalRoleSecurity.filter(
                          (sec) =>
                            sec.Functionality === "Security" && sec.Security > 0
                        ).length > 0 && (
                          <MenuItem
                            component={Link}
                            to={"/Security"}
                            color="inherit"
                            disableRipple
                            onClick={handleAdminClose}
                          >
                            <VpnLockOutlined />
                            {t("Security")}
                          </MenuItem>
                        )}
                      </StyledMenu>
                      |
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {globalUser === null ? (
                <Button
                  endIcon={<Login />}
                  component={Link}
                  to={"/LogIn"}
                  color="inherit"
                >
                  <span>{t("log in")}</span>
                </Button>
              ) : (
                <>
                  <Button
                    id="demo-customized-button"
                    aria-controls={
                      UserPOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={UserPOpen ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleUserPClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    <span style={{ padding: 0, margin: 0, fontSize: 10 }}>
                      {t("log out")}
                      <br></br>
                      <span style={{ fontSize: 8 }}>
                        {globalUser.Name.substring(0, 10)}
                      </span>
                    </span>
                    <Avatar sx={{ marginLeft: 1 }} src={globalUser.Logo} />
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorUserPEl}
                    open={UserPOpen}
                    onClose={handleUserPClose}
                  >
                    <MenuItem
                      component={Link}
                      to={"/LogIn"}
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      onClick={() => {
                        setGlobalUser(null);
                        setSelectedItems([]);
                        setGlobalRoleSecurity([]);
                        setPageHeader("");
                        handleUserPClose();
                      }}
                    >
                      <LogoutOutlined />
                      {t("log out")}
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ color: "black", borderRadius: 3 }}
                      //onClick={handleUserPClose}
                    >
                      <MessageOutlined />

                      <MessagesModel
                        Title={t("messages")}
                        data={globalUser}
                        icon={<div> {t("messages")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      sx={{ color: "black", borderRadius: 3 }}
                    >
                      <EvStationSharp />

                      <UserRegisterComp
                        Title="User Profile"
                        data={globalUser}
                        icon={<div>{t("user profile")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <ManageAccountsOutlined />

                      <UserAccountChange
                        Title="Change Account"
                        data={globalUser}
                        icon={<div>{t("change account")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <Language />
                      <TextField
                        id="Languagesfull"
                        select
                        size="small"
                        variant="standard"
                        defaultValue={selectedLanguage}
                        color="primary"
                        onChange={(e) => {
                          setSelectedLanguage(e.target.value);
                          i18n.changeLanguage(e.target.value);
                        }}
                        InputProps={{
                          style: { width: "90%" },
                        }}
                        sx={{ alignItems: "center", color: "black" }}
                        FormHelperTextProps={{ sx: { color: "black" } }}
                        helperText={t("select language")}
                      >
                        {Languegs.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </MenuItem>
                  </StyledMenu>
                </>
              )}
              {/*    <TextField
              id="Languagesfull"
              select
              size="small"
              variant="standard"
              defaultValue="en"
              color="primary"
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
              
              }}
              InputProps={{
                style: { color: "white", width: "90%" },
              }}
              sx={{ alignItems: "center" }}
              helperText="Select Language"
            >
              {Languegs.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
            </Stack>

            <Stack sx={{ display: { xs: "block", md: "none" } }}>
              {globalUser === null ? (
                <Button
                  endIcon={<Login />}
                  component={Link}
                  to={"/LogIn"}
                  color="inherit"
                >
                  <span>{t("log in")}</span>
                </Button>
              ) : (
                <>
                  <Button
                    id="demo-customized-button"
                    aria-controls={
                      UserPOpen ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={UserPOpen ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleUserPClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    <span style={{ padding: 0, margin: 0, fontSize: 10 }}>
                      {t("log out")}
                      <br></br>
                      <span style={{ fontSize: 8 }}>
                        {globalUser.Name.substring(0, 10)}
                      </span>
                    </span>
                    <Avatar sx={{ marginLeft: 1 }} src={globalUser.Logo} />
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorUserPEl}
                    open={UserPOpen}
                    onClose={handleUserPClose}
                  >
                    <MenuItem
                      component={Link}
                      to={"/LogIn"}
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      onClick={() => {
                        setGlobalUser(null);
                        setSelectedItems([]);
                        setGlobalRoleSecurity([]);
                        setPageHeader("");
                        handleUserPClose();
                      }}
                    >
                      <LogoutOutlined />
                      {t("log out")}
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <MessageOutlined />
                      <MessagesModel
                        Title={t("messages")}
                        data={globalUser}
                        icon={<div> {t("messages")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <ManageAccountsOutlined />

                      <UserRegisterComp
                        Title="User Profile"
                        data={globalUser}
                        icon={<div>{t("user profile")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      sx={{ color: "black", borderRadius: 3 }}
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <ManageAccountsOutlined />

                      <UserAccountChange
                        Title="Change Account"
                        data={globalUser}
                        icon={<div>{t("change account")}</div>}
                      />
                    </MenuItem>
                    <MenuItem
                      color="inherit"
                      disableRipple
                      //onClick={handleUserPClose}
                    >
                      <Language />
                      <TextField
                        id="Languagesfull"
                        select
                        size="small"
                        variant="standard"
                        sx={{ color: "black" }}
                        defaultValue={selectedLanguage}
                        color="primary"
                        onChange={(e) => {
                          setSelectedLanguage(e.target.value);
                          i18n.changeLanguage(e.target.value);
                        }}
                        InputProps={{
                          style: { width: "90%", color: "black" },
                        }}
                        FormHelperTextProps={{ sx: { color: "black" } }}
                        helperText={t("select language")}
                      >
                        {Languegs.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </MenuItem>
                  </StyledMenu>
                </>
              )}

              <IconButton
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="right"
                variant="standard"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                PaperProps={{
                  sx: {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenDrawer(false);
                    }}
                    endIcon={<KeyboardReturnOutlined />}
                  ></Button>
                  <Button
                    component={Link}
                    to={"/News"}
                    variant="contained"
                    onClick={() => setOpenDrawer(false)}
                  >
                    {t("news")}
                  </Button>
                  <Button
                    component={Link}
                    to={"/AboutUs"}
                    variant="contained"
                    onClick={() => setOpenDrawer(false)}
                  >
                    {t("about us")}
                  </Button>
                  {!(globalUser === null) ? (
                    <>
                      {globalRoleSecurity.filter(
                        (sec) =>
                          sec.Department === "Inventory" && sec.Security > 0
                      ).length > 0 && (
                        <>
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              InventoryOpen ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={InventoryOpen ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={(e) => handleInventoryClick(e)}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Inventory
                          </Button>
                          <StyledMenu
                            backgroundColor="black"
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorInventoryEl}
                            open={InventoryOpen}
                            onClose={handleInventoryClose}
                          >
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Request" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemJournals"}
                                state={{
                                  Status: "Request",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Issue Request" &&
                                      sec.Security > 0
                                  )[0].Store,
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Issue Request
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Approval" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemJournals"}
                                state={{
                                  Status: "Approved",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Issue Approval" &&
                                      sec.Security > 0
                                  )[0].Store,
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Issue Approval
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issued" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemJournals"}
                                state={{
                                  Status: "Issued",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Issued" &&
                                      sec.Security > 0
                                  )[0].Store,
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Issued
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Approval" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemJournals"}
                                state={{
                                  Status: "Cleared",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Issue Approval" &&
                                      sec.Security > 0
                                  )[0].Store,
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Issue Cleared
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Return" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemJournals"}
                                state={{
                                  Status: "Return",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Issue Return" &&
                                      sec.Security > 0
                                  )[0].Store,
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Issue Returned
                              </MenuItem>
                            )}

                            <Divider sx={{ my: 0.5 }} />
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Request" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Request" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Request")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Approved" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Approved" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Approved")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Issue" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Issued" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Issue")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Received" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Received" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Received")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Direct" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Direct" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Direct")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Transfer Cleared" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/TransferOrder"}
                                state={{ Status: "Cleared" }}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                {t("Transfer Cleared")}
                              </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />

                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Physical Count" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PhysicalCount"}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Physical Count
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Cost Adjustment" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/CostAdjustment"}
                                color="inherit"
                                disableRipple
                                onClick={handleInventoryClose}
                              >
                                <EvStationSharp />
                                Cost Adjustment
                              </MenuItem>
                            )}
                          </StyledMenu>
                        </>
                      )}

                      {globalRoleSecurity.filter(
                        (sec) => sec.Department === "Sales" && sec.Security > 0
                      ).length > 0 && (
                        <>
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              SalesOpen ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={SalesOpen ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleSalesClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            {t("sales")}
                          </Button>
                          <StyledMenu
                            backgroundColor="black"
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorSalesEl}
                            open={SalesOpen}
                            onClose={handleSalesClose}
                          >
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Sales Request" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <>
                                <MenuItem color="inherit">
                                  <Badge
                                    badgeContent={
                                      selectedItems.filter(
                                        (it) => it.Type === "Sales"
                                      ).length
                                    }
                                    color="secondary"
                                  >
                                    <SalesNewOrder
                                      data={null}
                                      Type="Sales"
                                      Status={selectedSalesType}
                                      icon={
                                        <Stack direction="row">
                                          <AddShoppingCartIcon
                                            style={{
                                              marginRight: 8,
                                              marginLeft: 8,
                                            }}
                                          />
                                          <span style={{ fontSize: 14 }}>
                                            New Order
                                          </span>
                                        </Stack>
                                      }
                                      Title="title"
                                    />
                                  </Badge>{" "}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={"/SalesProducts"}
                                  color="inherit"
                                  disableRipple
                                  onClick={handleSalesClose}
                                >
                                  <EvStationSharp />
                                  {t("Sales Products")}
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                  component={Link}
                                  to={"/SalesVouchersList"}
                                  state={{
                                    Type: "Sales",
                                    Store: globalRoleSecurity.filter(
                                      (sec) =>
                                        sec.Functionality === "Sales Request" &&
                                        sec.Security > 0
                                    )[0].Store,
                                    Status: "Draft",
                                  }}
                                  color="inherit"
                                  disableRipple
                                  onClick={handleSalesClose}
                                >
                                  <DraftsSharp />
                                  {t("Draft")}
                                </MenuItem>
                                <MenuItem
                                  component={Link}
                                  to={"/SalesVouchersList"}
                                  state={{
                                    Type: "Sales",
                                    Store: globalRoleSecurity.filter(
                                      (sec) =>
                                        sec.Functionality === "Sales Request" &&
                                        sec.Security > 0
                                    )[0].Store,
                                    Status: "Proforma",
                                  }}
                                  color="inherit"
                                  disableRipple
                                  onClick={handleSalesClose}
                                >
                                  <EvStationSharp />
                                  {t("Proforma")}
                                </MenuItem>
                              </>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Sales Order" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/SalesVouchersList"}
                                state={{
                                  Type: "Sales",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Sales Order" &&
                                      sec.Security > 0
                                  )[0].Store,
                                  Status: "Order",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleSalesClose}
                              >
                                <EvStationSharp />
                                {t("Orders")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Sales Delivery" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/SalesVouchersList"}
                                state={{
                                  Type: "Sales",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Sales Delivery" &&
                                      sec.Security > 0
                                  )[0].Store,
                                  Status: "Delivery",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleSalesClose}
                              >
                                <LocalShippingOutlined />
                                {t("Delivery")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Sales Return" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/SalesVouchersList"}
                                state={{
                                  Type: "Sales",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Sales Return" &&
                                      sec.Security > 0
                                  )[0].Store,
                                  Status: "Return",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleSalesClose}
                              >
                                <EvStationSharp />
                                {t("Return")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Sales Cleared" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/SalesVouchersList"}
                                state={{
                                  Type: "Sales",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality === "Sales Cleared" &&
                                      sec.Security > 0
                                  )[0].Store,
                                  Status: "Cleared",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handleSalesClose}
                              >
                                <EvStationSharp />
                                {t("Cleared")}
                              </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/Payments"}
                              state={{ Type: "Sales" }}
                              color="inherit"
                              disableRipple
                              onClick={handleSalesClose}
                            >
                              <EvStationSharp />
                              {t("Payment")}
                            </MenuItem>
                          </StyledMenu>
                        </>
                      )}
                      {globalRoleSecurity.filter(
                        (sec) =>
                          sec.Department === "Purchase" && sec.Security > 0
                      ).length > 0 && (
                        <>
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              purchaseOpen ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={purchaseOpen ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handlePurchaseClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Purchase
                          </Button>
                          <StyledMenu
                            backgroundColor="black"
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorPurchaseEl}
                            open={purchaseOpen}
                            onClose={handlePurchaseClose}
                          >
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Request" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <>
                                <MenuItem color="inherit">
                                  <Badge
                                    badgeContent={
                                      selectedItems.filter(
                                        (it) => it.Type === "Purchase"
                                      ).length
                                    }
                                    color="secondary"
                                  >
                                    <NewOrder
                                      data={null}
                                      Type="Purchase"
                                      Status={selectedPurchaseType}
                                      icon={
                                        <Stack direction="row">
                                          <AddShoppingCartIcon />
                                          New Order
                                        </Stack>
                                      }
                                      Title="title"
                                    />
                                  </Badge>{" "}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={"/PurchaseProducts"}
                                  color="inherit"
                                  disableRipple
                                  onClick={handlePurchaseClose}
                                >
                                  <EvStationSharp />
                                  {t("Purchase Products")}
                                </MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem
                                  component={Link}
                                  to={"/PurchaseVouchersList"}
                                  state={{
                                    Type: "Purchase",
                                    Store: globalRoleSecurity.filter(
                                      (sec) =>
                                        sec.Functionality ===
                                          "Purchase Request" && sec.Security > 0
                                    )[0].Store,
                                    Status: "Draft",
                                  }}
                                  color="inherit"
                                  disableRipple
                                  onClick={handlePurchaseClose}
                                >
                                  <DraftsSharp />
                                  {t("Draft")}
                                </MenuItem>

                                <MenuItem
                                  component={Link}
                                  to={"/PurchaseVouchersList"}
                                  state={{
                                    Type: "Purchase",
                                    Store: globalRoleSecurity.filter(
                                      (sec) =>
                                        sec.Functionality ===
                                          "Purchase Request" && sec.Security > 0
                                    )[0].Store,
                                    Status: "Request",
                                  }}
                                  color="inherit"
                                  disableRipple
                                  onClick={handlePurchaseClose}
                                >
                                  <EvStationSharp />
                                  {t("Request")}
                                </MenuItem>
                              </>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Proforma" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseVouchersList"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Approved",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <ApprovalSharp />
                                {t("Approved Request")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Proforma" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseProformaComp"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Proforma",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <ApprovalSharp />
                                {t("Purchase Proforma")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Order" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseVouchersList"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Order",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <EvStationSharp />
                                {t("Orders")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Receipt" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseProformaComp"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Received",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <LocalShippingOutlined />
                                {t("Received")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Return" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseVouchersList"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Return",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <AssignmentReturnOutlined />
                                {t("Return")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase Cleared" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/PurchaseVouchersList"}
                                state={{
                                  Type: "Purchase",
                                  Store: globalRoleSecurity.filter(
                                    (sec) =>
                                      sec.Functionality ===
                                        "Purchase Request" && sec.Security > 0
                                  )[0].Store,
                                  Status: "Cleared",
                                }}
                                color="inherit"
                                disableRipple
                                onClick={handlePurchaseClose}
                              >
                                <EvStationSharp />
                                {t("Cleared")}
                              </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/Payments"}
                              state={{ Type: "Purchase" }}
                              color="inherit"
                              disableRipple
                              onClick={handlePurchaseClose}
                            >
                              <EvStationSharp />
                              {t("Payment")}
                            </MenuItem>
                          </StyledMenu>
                        </>
                      )}
                      {globalRoleSecurity.filter(
                        (sec) => sec.Department === "Admin" && sec.Security > 0
                      ).length > 0 && (
                        <>
                          <Button
                            id="demo-customized-button"
                            aria-controls={
                              AdminOpen ? "demo-customized-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={AdminOpen ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleAdminClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            {t("admins")}
                          </Button>
                          <StyledMenu
                            backgroundColor={theme.palette.primary.dark}
                            id="demo-customized-menu"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button",
                            }}
                            anchorEl={anchorAdminEl}
                            open={AdminOpen}
                            onClose={handleAdminClose}
                          >
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Users" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/Users"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <PeopleOutline />
                                {t("Users")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Customers" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/Customers"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <PeopleAlt />
                                {t("Customers")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Suppliers" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/Suppliers"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <PeopleAlt />
                                {t("Suppliers")}
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Prospects" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/CustomersForApproval"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <EvStationSharp />
                                {t("Prospects")}
                              </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Stock Balance" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/StockBalances"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <Inventory />
                                Stock Balances
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Stores" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/Stores"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <WarehouseOutlined />
                                Stores
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Items" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/Items"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <CategoryRounded />
                                Items
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Items" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/SubItems"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <CategoryRounded />
                                Sub Items
                              </MenuItem>
                            )}
                            {globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Item Category" &&
                                sec.Security > 0
                            ).length > 0 && (
                              <MenuItem
                                component={Link}
                                to={"/ItemCategories"}
                                color="inherit"
                                disableRipple
                                onClick={handleAdminClose}
                              >
                                <CategoryOutlined />
                                Item Categories
                              </MenuItem>
                            )}
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                              component={Link}
                              to={"/Reports"}
                              color="inherit"
                              disableRipple
                              onClick={handleAdminClose}
                            >
                              <AssessmentOutlined />
                              {t("Reports")}
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              to={"/Setting"}
                              color="inherit"
                              disableRipple
                              onClick={handleAdminClose}
                            >
                              <SettingsOutlined />
                              {t("Setting")}
                            </MenuItem>
                            <MenuItem
                              component={Link}
                              to={"/Security"}
                              color="inherit"
                              disableRipple
                              onClick={handleAdminClose}
                            >
                              <VpnLockOutlined />
                              {t("Security")}
                            </MenuItem>
                          </StyledMenu>
                        </>
                      )}
                      {/*   {globalUser && (globalUser!==null) &&
                <IconButton variant="standard">
              
              <Badge badgeContent={selectedItems.length} color="secondary">
              <NewOrder data={null} Type="Purchase" icon={<AddShoppingCartIcon />} Title="title" />
              </Badge>{" "}
            </IconButton>
} */}
                    </>
                  ) : (
                    ""
                  )}
                  {globalUser === null ? (
                    <Button
                      endIcon={<Login />}
                      component={Link}
                      to={"/LogIn"}
                      color="inherit"
                    >
                      <span>{t("log in")}</span>
                    </Button>
                  ) : (
                    <>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          UserPOpen ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={UserPOpen ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleUserPClick}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        <span style={{ padding: 0, margin: 0, fontSize: 10 }}>
                          {t("log out")}
                          <br></br>
                          <span style={{ fontSize: 8 }}>
                            {globalUser.Name.substring(0, 10)}
                          </span>
                        </span>
                        <Avatar sx={{ marginLeft: 1 }} src={globalUser.Logo} />
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorUserPEl}
                        open={UserPOpen}
                        onClose={handleUserPClose}
                      >
                        <MenuItem
                          component={Link}
                          to={"/LogIn"}
                          sx={{ color: "black", borderRadius: 3 }}
                          disableRipple
                          onClick={() => {
                            setGlobalUser(null);
                            setSelectedItems([]);
                            setGlobalRoleSecurity([]);
                            setPageHeader("");
                            handleUserPClose();
                          }}
                        >
                          <LogoutOutlined />
                          {t("log out")}
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "black", borderRadius: 3 }}
                          disableRipple
                          //onClick={handleUserPClose}
                        >
                          <MessageOutlined />
                          <MessagesModel
                            Title={t("messages")}
                            data={globalUser}
                            icon={<div> {t("messages")}</div>}
                          />
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "black", borderRadius: 3 }}
                          disableRipple
                          //onClick={handleUserPClose}
                        >
                          <ManageAccountsOutlined />

                          <UserRegisterComp
                            Title="User Profile"
                            data={globalUser}
                            icon={<div>{t("user profile")}</div>}
                          />
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "black", borderRadius: 3 }}
                          disableRipple
                          //onClick={handleUserPClose}
                        >
                          <ManageAccountsOutlined />

                          <UserAccountChange
                            Title="Change Account"
                            data={globalUser}
                            icon={<div>{t("change account")}</div>}
                          />
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "black", borderRadius: 3 }}
                          disableRipple
                          //onClick={handleUserPClose}
                        >
                          <Language />
                          <TextField
                            id="Languagesfull"
                            select
                            size="small"
                            variant="standard"
                            defaultValue={selectedLanguage}
                            color="primary"
                            onChange={(e) => {
                              setSelectedLanguage(e.target.value);
                              i18n.changeLanguage(e.target.value);
                            }}
                            InputProps={{
                              style: { width: "90%" },
                            }}
                            sx={{ alignItems: "center", color: "black" }}
                            FormHelperTextProps={{ sx: { color: "black" } }}
                            helperText={t("select language")}
                          >
                            {Languegs.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </MenuItem>
                      </StyledMenu>
                    </>
                  )}
                </Stack>
              </SwipeableDrawer>
            </Stack>
          </Toolbar>
        </AppBar>

        <div className="App">
          <Routes>
            <Route path="/" element={<LogInModel />} />
            <Route path="/Home" element={<HomeComp />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/StockBalances" element={<StockBalancesComp />} />
            <Route path="/Stores" element={<StoresComp />} />
            <Route path="/SalesRoutes" element={<SalesRoutesComp />} />
            <Route path="/Items" element={<ItemsComp />} />
            <Route path="/ItemCategories" element={<ItemCategoriesComp />} />
            <Route path="/Suppliers" element={<SuppliersComp />} />
            <Route path="/Customers" element={<ComstomersComp />} />
            <Route
              path="/CustomersForApproval"
              element={<CustomersCompForApproval />}
            />
            <Route path="/Users" element={<UsersComp />} />
            <Route path="/SalesVouchersList" element={<SalesVouchersComp />} />
            <Route
              path="/PurchaseVouchersList"
              element={<PurchaseVouchersComp />}
            />
            <Route
              path="/PurchaseProformaComp"
              element={<PurchaseProformaComp />}
            />
            <Route path="/PurchaseHistory" element={<PurchaseHistoryComp />} />

            <Route path="/JobsOrder" element={<JobOrdersComp />} />

            <Route path="/Payments" element={<PaymentsComp />} />
            <Route path="/ProductDetails" element={<ProductDetails />} />
            <Route path="/SalesProducts" element={<LandingSalesComp />} />
            <Route path="/PurchaseProducts" element={<LandingPurchaseComp />} />
            <Route path="/Maps" element={<GMaps />} />
            <Route path="/News" element={<NewsComp />} />
            <Route path="/SingleStory" element={<SingleStory />} />
            <Route path="/EditStory" element={<EditStory />} />
            <Route path="/Reports" element={<ReportInterface />} />
            <Route path="/ItemJournals" element={<ItemJournalComp />} />
            <Route path="/TransferOrder" element={<ItemTransferComp />} />
            <Route path="/ChartofAccounts" element={<ChartOfAccountComp />} />
            <Route path="/ProductPostingSetup" element={<ProductSetupComp />} />
            <Route
              path="/BusinessPostingSetup"
              element={<BusinessSetupComp />}
            />
            <Route
              path="/GeneralLedgerEntries"
              element={<GeneralLedgerEntriesComp />}
            />
            <Route
              path="/GeneralJournalComp"
              element={<GeneralJournalComp Type="New" />}
            />
            <Route path="/FixedAssetsList" element={<FixedAssetsComp />} />
            <Route path="/FAPostingSetup" element={<FixedAssetSetupsComp />} />

            <Route path="/ReceptionComp" element={<ReceptionComp />} />
            <Route path="/TriageComp" element={<TriageComp />} />
            <Route path="/DiagnosisComp" element={<DiagnosisComp />} />
            <Route path="/LaboratoryComp" element={<LaboratoryComp />} />
            <Route path="/CashControlComp" element={<CashControlComp />} />

            <Route path="/ImagingComp" element={<ImagingComp />} />
            <Route path="/Setting" element={<SettingComp />} />
            <Route path="/Security" element={<SecurityComp />} />

            <Route path="/AboutUs" element={<AboutusComp />} />

            <Route path="*" element={<LogInModel />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
    //</MuiThemeProvider>
  );
}

export default App;
