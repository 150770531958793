import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:4000",
  //baseURL: "http://172.16.30.39:4000",
  baseURL: "https://api.ethiob2b.com/", //production
  //baseURL: "https://192.250.239.60:4000/"
});

export default api;
